import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import Login from './Login';
import Footer from './Footer';
import { getTokenFromUrl, spotify } from './spotify';
import spotifyWhiteLogo from './spotify-logo-white.png';

const WELSH_PLAYLIST_ID = '0NcBJk5mJKEwCpOdv2jrYP';
const BASE_DELAY = 1500;

// Utility functions
const getPopularityTier = (popularity) => {
  if (popularity >= 80) return 'mainstream';
  if (popularity >= 60) return 'popular';
  if (popularity >= 40) return 'rising';
  return 'emerging';
};

const getGenreScore = (genre1, genre2) => {
  const g1 = genre1.toLowerCase();
  const g2 = genre2.toLowerCase();
  if (g1 === g2) return 1;
  if (g1.includes(g2) || g2.includes(g1)) return 0.5;
  const commonGenreWords = ['rock', 'pop', 'folk', 'electronic', 'indie', 'alternative'];
  if (commonGenreWords.some(word => g1.includes(word) && g2.includes(word))) return 0.3;
  return 0;
};

const getArtistConnectionScore = (topArtistGenres, welshArtistGenres) => {
  if (!topArtistGenres?.length || !welshArtistGenres?.length) return 0;
  
  const genreOverlaps = welshArtistGenres.map(wGenre =>
    topArtistGenres.map(tGenre => getGenreScore(wGenre, tGenre))
      .reduce((max, score) => Math.max(max, score), 0)
  );

  return genreOverlaps.reduce((sum, score) => sum + score, 0) / genreOverlaps.length;
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const LoadingState = ({ message }) => (
  <div className="loading">
    <div className="loading-spinner"></div>
    <p>{message || 'Loading...'}</p>
  </div>
);

const EnhancingNotice = () => (
  <div className="enhancing-notice">
    <div className="enhancing-spinner"></div>
    <p>Enhancing recommendations based on deep musical analysis...</p>
  </div>
);

function App() {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [topTracks, setTopTracks] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enhancing, setEnhancing] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(null);
  const [selectedTracks, setSelectedTracks] = useState(new Set());

  const toggleTrackSelection = useCallback((trackId) => {
    setSelectedTracks(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(trackId)) {
        newSelection.delete(trackId);
      } else {
        newSelection.add(trackId);
      }
      return newSelection;
    });
  }, []);

  const getRecommendationReason = useCallback((welshTrack, userTopTracks) => {
    if (!userTopTracks?.length) {
      return "This track matches your musical preferences.";
    }

    const matchingArtist = userTopTracks.find(track => 
      track.artists[0].name === welshTrack.artists[0].name
    );
    
    if (matchingArtist) {
      return `We think you'll like this because you've recently listened to ${matchingArtist.name} by ${matchingArtist.artists[0].name}.`;
    }

    const { scores, matchedGenres } = welshTrack;
    if (scores?.genre > 0.7 && matchedGenres?.length > 0) {
      const genreExample = matchedGenres[0];
      return `This track matches your taste in ${genreExample} music.`;
    }

    if (scores?.basic > 0.7) {
      return "This track's popularity and style align well with your listening history.";
    }

    const randomTopTrack = userTopTracks[Math.floor(Math.random() * userTopTracks.length)];
    return `This track has similar musical elements to ${randomTopTrack.name} by ${randomTopTrack.artists[0].name}.`;
  }, []);

  const getPopularityAndDateSimilarity = useCallback((topTracks, welshTracks) => {
    try {
      setLoadingMessage('Analyzing popularity patterns...');
      
      const avgPopularity = topTracks.reduce((sum, t) => sum + t.popularity, 0) / topTracks.length;
      const avgReleaseYear = topTracks.reduce((sum, t) => {
        const year = new Date(t.album.release_date).getFullYear();
        return sum + (isNaN(year) ? 2000 : year);
      }, 0) / topTracks.length;
      
      const maxYearDiff = 50;
      const userTierPreference = getPopularityTier(avgPopularity);
      
      const currentYear = new Date().getFullYear();
      const getRecencyScore = (year) => {
        const age = currentYear - year;
        return Math.max(0, 1 - (age / maxYearDiff));
      };
      
      return welshTracks.map(track => {
        const popularityDiff = Math.abs(track.popularity - avgPopularity) / 100;
        const yearDiff = Math.abs(
          new Date(track.album.release_date).getFullYear() - avgReleaseYear
        ) / maxYearDiff;
        
        const popularityScore = 1 - popularityDiff;
        const yearScore = 1 - yearDiff;
        const tierBonus = getPopularityTier(track.popularity) === userTierPreference ? 0.2 : 0;
        const recencyScore = getRecencyScore(new Date(track.album.release_date).getFullYear());
        
        const score = (
          (popularityScore * 0.4) +
          (yearScore * 0.3) +
          (tierBonus) +
          (recencyScore * 0.3)
        );
        
        return { ...track, score: Math.min(score, 1) };
      });
    } catch (error) {
      console.error("Error in getPopularityAndDateSimilarity:", error);
      return welshTracks.map(track => ({ ...track, score: 0.5 }));
    }
  }, []);

  const getGenreSimilarity = useCallback(async (topTracks, welshTracks) => {
    try {
      setLoadingMessage('Analyzing musical styles in depth...');
      
      const topTracksSubset = topTracks.slice(0, 15);
      const welshTracksSubset = welshTracks.slice(0, 20);
      
      const uniqueArtistIds = [...new Set([
        ...topTracksSubset.flatMap(t => t.artists.map(a => a.id)),
        ...welshTracksSubset.flatMap(t => t.artists.map(a => a.id))
      ])];

      const artistBatches = [];
      for (let i = 0; i < uniqueArtistIds.length; i += 5) {
        const batch = uniqueArtistIds.slice(i, i + 5);
        await delay(300);
        const artists = await Promise.all(
          batch.map(id => spotify.getArtist(id).catch(() => null))
        );
        artistBatches.push(...artists.filter(Boolean));
      }

      const artistMap = new Map(
        artistBatches.map(artist => [artist.id, artist])
      );

      const topGenres = new Set(
        topTracksSubset.flatMap(track => 
          track.artists
            .map(artist => artistMap.get(artist.id)?.genres || [])
            .flat()
        )
      );

      return welshTracks.map(track => {
        const artistGenres = track.artists
          .map(artist => artistMap.get(artist.id)?.genres || [])
          .flat();
        
        if (!artistGenres.length) return { ...track, genreScore: 0.5 };

        const genreScores = artistGenres.map(genre => 
          [...topGenres].map(topGenre => getGenreScore(genre, topGenre))
            .reduce((max, score) => Math.max(max, score), 0)
        );
        
        const connectionScores = track.artists.map(artist => {
          const artistGenres = artistMap.get(artist.id)?.genres || [];
          const topArtistGenres = [...topGenres];
          return getArtistConnectionScore(topArtistGenres, artistGenres);
        });

        const avgGenreScore = genreScores.reduce((sum, score) => sum + score, 0) / genreScores.length;
        const avgConnectionScore = connectionScores.reduce((sum, score) => sum + score, 0) / connectionScores.length;
        
        const genreScore = (avgGenreScore * 0.7) + (avgConnectionScore * 0.3);
        return { ...track, genreScore, matchedGenres: artistGenres };
      });
    } catch (error) {
      console.warn("Genre analysis partial failure:", error);
      return welshTracks.map(track => ({ ...track, genreScore: 0.5, matchedGenres: [] }));
    }
  }, []);

  const getRecommendations = useCallback(async (topTracks, welshTracks) => {
    let basicScores = [];
    
    try {
      setEnhancing(false);
      
      basicScores = getPopularityAndDateSimilarity(topTracks, welshTracks);
      
      const initialRecommendations = basicScores
        .sort((a, b) => b.score - a.score)
        .slice(0, 12);
      
      setRecommendations(initialRecommendations);
      setEnhancing(true);

      const genreResults = await getGenreSimilarity(topTracks, welshTracks);
      
      const combinedScores = welshTracks.map(track => {
        const basic = basicScores.find(t => t.id === track.id)?.score || 0.5;
        const genre = genreResults.find(t => t.id === track.id)?.genreScore || 0.5;
        const matchedGenres = genreResults.find(t => t.id === track.id)?.matchedGenres || [];
        
        const totalScore = (basic * 0.3) + (genre * 0.7);
        return { 
          ...track, 
          totalScore,
          matchedGenres,
          scores: { basic, genre }
        };
      });

      setEnhancing(false);
      
      return combinedScores
        .sort((a, b) => b.totalScore - a.totalScore)
        .slice(0, 12);
    } catch (error) {
      console.error("Error in getRecommendations:", error);
      setEnhancing(false);
      
      return basicScores
        .sort((a, b) => b.score - a.score)
        .slice(0, 12)
        .map(track => ({ ...track, totalScore: track.score }));
    }
  }, [getPopularityAndDateSimilarity, getGenreSimilarity]);

  const handleCreatePlaylist = useCallback(async () => {
    if (!user || !selectedTracks.size) {
      alert("Please select at least one track to add to your playlist.");
      return;
    }
    
    try {
      setLoadingMessage('Creating playlist...');
      setLoading(true);
      
      const date = new Date().toLocaleDateString();
      const playlistName = `Miwsig Mêt Recommendations ${date}`;
      
      const playlist = await spotify.createPlaylist(user.id, {
        name: playlistName,
        description: 'Welsh music recommendations from Miwsig Mêt',
        public: false
      });

      const selectedTrackObjects = recommendations.filter(track => 
        selectedTracks.has(track.id)
      );
      await spotify.addTracksToPlaylist(playlist.id, selectedTrackObjects.map(track => track.uri));
      alert(`Playlist "${playlistName}" created with ${selectedTracks.size} tracks!`);
      setSelectedTracks(new Set()); // Clear selections after successful creation
    } catch (error) {
      console.error("Error creating playlist:", error);
      setError("Failed to create playlist. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [user, recommendations, selectedTracks]);

  useEffect(() => {
    const tokenFromStorage = window.localStorage.getItem('spotify_token');
    
    if (tokenFromStorage) {
      console.log("Found token in storage");
      setToken(tokenFromStorage);
      spotify.setAccessToken(tokenFromStorage);
    } else {
      const hash = getTokenFromUrl();
      window.location.hash = "";
      const _token = hash.access_token;
      
      if (_token) {
        console.log("Found token in URL");
        window.localStorage.setItem('spotify_token', _token);
        setToken(_token);
        spotify.setAccessToken(_token);
      }
    }
  }, []);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoadingMessage('Fetching your music profile...');
        const userData = await spotify.getMe();
        setUser(userData);

        setLoadingMessage('Analyzing your listening history...');
        const topTracksData = await spotify.getMyTopTracks({ 
          limit: 50, 
          time_range: 'short_term'
        });
        setTopTracks(topTracksData.items);

        setLoadingMessage('Finding Welsh tracks...');
        const welshPlaylistTracks = await spotify.getPlaylistTracks(WELSH_PLAYLIST_ID);
        const welshTracks = welshPlaylistTracks.items.map(item => item.track);

        const recommendedTracks = await getRecommendations(topTracksData.items, welshTracks);
        setRecommendations(recommendedTracks);
        setLoading(false);
      } catch (error) {
        console.error("Error in data fetch:", error);
        
        if (error.status === 401) {
          console.log("Token expired or invalid, clearing storage");
          window.localStorage.removeItem('spotify_token');
          setToken(null);
          setError("Session expired. Please login again.");
        } else {
          setError("Failed to load data. Please try again.");
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [token, getRecommendations]);

  if (loading) {
    return <LoadingState message={loadingMessage} />;
  }

 if (error) {
    return (
      <div className="error">
        {error}
        {error.includes("Session expired") && (
          <button 
            onClick={() => window.location.reload()}
            className="retry-btn"
          >
            Login Again
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="app">
      {token ? (
        <div className="content">
          <header>
            <h1>Croeso i Miwsig Mêt, {user?.display_name}!</h1>
            <div className="header-right">
              {user?.images?.[0]?.url && (
                <img src={user.images[0].url} alt="Profile" className="profile-pic"/>
              )}
              <a 
                href="https://www.spotify.com/account/apps/"
                className="disconnect-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Disconnect from Spotify
              </a>
            </div>
          </header>
          
          <section className="recommendations">
            <h2>Recommended Welsh Tracks</h2>
            <p className="intro-text">
              Based on your recent listening preferences, here are some Welsh-language tracks you might enjoy:
            </p>
            {enhancing && <EnhancingNotice />}
            <button 
              onClick={handleCreatePlaylist} 
              className="create-playlist-btn"
              disabled={loading || !selectedTracks.size}
            >
              {loading ? 'Creating...' : 
                selectedTracks.size ? 
                `Add ${selectedTracks.size} Selected Tracks to Playlist` : 
                'Select tracks to add to playlist'}
            </button>
            <div className="track-list">
              {recommendations.map(track => (
                <div key={track.id} className="track-item">
                  <div className="track-selection">
                    <input
                      type="checkbox"
                      checked={selectedTracks.has(track.id)}
                      onChange={() => toggleTrackSelection(track.id)}
                      id={`track-${track.id}`}
                      className="track-checkbox"
                    />
                    <label htmlFor={`track-${track.id}`} className="track-checkbox-label">
                      Select for playlist
                    </label>
                  </div>
                  <a 
                    href={track.external_urls.spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="track-link"
                  >
                    <img 
                      src={track.album.images[0].url} 
                      alt={track.name} 
                      className="track-image"
                    />
                    <div className="spotify-badge">
                      <img 
                        src={spotifyWhiteLogo}
                        alt="Listen on Spotify" 
                        className="spotify-icon"
                      />
                    </div>
                  </a>
                  <div className="track-info">
                    <p className="track-name">
                      <a 
                        href={track.external_urls.spotify}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {track.name}
                      </a>
                    </p>
                    <p className="track-artist">
                      <a 
                        href={track.artists[0].external_urls.spotify}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {track.artists[0].name}
                      </a>
                    </p>
                    <div className="track-preview">
                      {track.preview_url ? (
                        <div className="preview-available">
                          <audio controls src={track.preview_url}>
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ) : (
                        <div className="preview-unavailable">
                          <p>Preview not available</p>
                          <a 
                            href={track.external_urls.spotify}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="listen-on-spotify-btn"
                          >
                            Listen on Spotify
                          </a>
                        </div>
                      )}
                    </div>
                    <p className="recommendation-reason">
                      {getRecommendationReason(track, topTracks)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>
          <Footer />
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;