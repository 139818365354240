import React from 'react';
import spotifyGreenLogo from './spotify-logo-green.png';

function Footer() {
   return (
       <footer className="footer">
           <div className="footer-content">
               <div className="footer-links">
                   <a href="./privacy.html" target="_blank">Privacy Policy</a>
                   <span className="divider">|</span>
                   <a href="./terms.html" target="_blank">Terms of Use</a>
               </div>
               <div className="spotify-attribution">
                   <span>Powered by</span>
                   <a href="https://www.spotify.com" target="_blank" rel="noopener noreferrer">
                       <img 
  src={spotifyGreenLogo}
  alt="Spotify" 
  className="spotify-logo"
/>
                   </a>
               </div>
           </div>
       </footer>
   );
}

export default Footer;