import SpotifyWebApi from "spotify-web-api-js";

export const authEndpoint = "https://accounts.spotify.com/authorize";

// Dynamic redirect URI based on environment with debugging
const redirectUri = window.location.hostname === 'localhost' 
  ? "http://localhost:3000"
  : "https://miwsigmet.creu.cymru";

// Debug logging
console.log('Current hostname:', window.location.hostname);
console.log('Redirect URI being used:', redirectUri);

const clientId = "e070e4c43aac4009bffea090e7d5f0e9";

const scopes = [
  "user-read-currently-playing",
  "user-read-recently-played",
  "user-top-read",
  "playlist-modify-private",
  "playlist-modify-public",
  "user-read-email",
  "user-read-private",
  "playlist-read-private",
  "playlist-read-collaborative",
  "user-library-read"
];

export const getTokenFromUrl = () => {
  return window.location.hash
    .substring(1)
    .split('&')
    .reduce((initial, item) => {
      let parts = item.split('=');
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};

// Create login URL with debug logging
const constructedLoginUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`;
console.log('Constructed login URL:', constructedLoginUrl);

export const loginUrl = constructedLoginUrl;

export const spotify = new SpotifyWebApi();